import React from "react";
import "./Hero.css";
import { Header } from "./Header/Header";
import hero_image_back from '../assets/hero_image_back.png'
import hero_image from '../assets/hero_image.png'
import Heart from '../assets/heart.png'
import Calories from '../assets/calories.png'
import NumberCounter from 'number-counter'

const Hero = () => {
  return (
    <div className="hero" id="hero">
    <div className="blur hero__blur"></div>
      <div className="left__hero">
        <Header />
        {/* The best Ad */}
        <div className="the_best_ad">
          <div></div>
          <span>The best fitness club in the town</span>
        </div>
        {/* Header heading */}
        <div className="hero__text">
          <div>
            <span className="stroke__text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your body and live up
              your life to fullest
            </span>
          </div>
        </div>
        {/* Figures */}
        <div className="hero__figures">
          <div>
            <span>
            <NumberCounter end={140} start={100} delay='4' preFix="+"></NumberCounter>
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={850} delay='4' preFix="+"></NumberCounter></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={50} start={20} delay='4' preFix="+"></NumberCounter></span>
            <span>fitness programs</span>
          </div>
        </div>
        <div className="hero__buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right__hero">
        <button className="btn">join now</button>

        <div className="hero__heart_rate">
        <img src={Heart} alt="" />
          <span>Heart Rate</span><span> 118 bpm</span>
        </div>
        <div className="hero__images">
          <img src={hero_image} alt="" className="hero__image" />
          <img src={hero_image_back} alt="" className="hero__image_back" />

          <div className="hero__calories">
            <img src={Calories} alt="" />
            <div>
              <span>Calories burned</span>
              <span>220 kcal</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
