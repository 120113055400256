import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData";
import RightArrow from '../../assets/rightArrow.png'

const Programs = () => {
  return (
    <div className="Programs" id="programs">
      <div className="Programs__header">
        <span className="stroke__text">Explore Our</span>
        <span>Programs</span>
        <span className="stroke__text">to shape You</span>
      </div>
      <div className="programs__categories">
        {programsData.map((program, i) => {
          return (
            <div className="category" key={i}>
              {program.image}
              <span>{program.heading}</span>
              <span>{program.details}</span>
              <div className="join__now"><span>join Now</span><img src={RightArrow} alt="" /></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Programs;
