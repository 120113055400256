import React from "react";
import "./Reasons.css";
import Image1 from "../../assets/image1.png";
import Image2 from "../../assets/image2.png";
import Image3 from "../../assets/image3.png";
import Image4 from "../../assets/image4.png";
import Nb from "../../assets/nb.png";
import Adidas from '../../assets/adidas.png'
import Nike from "../../assets/nike.png";
import Tick from "../../assets/tick.png";
const Reasons = () => {
  return (
    <div className="reasons" id="reasons">
      <div className="left__r">
        <img src={Image1} alt="" />
        <img src={Image2} alt="" />
        <img src={Image3} alt="" />
        <img src={Image4} alt="" />
      </div>
      <div className="right__r">
        <span>Some Reasons</span>

        <div>
          <span className="stroke__text">Why</span>
          <span> Choose Us</span>
        </div>
        <div className="details__r">
          <div>
            <img src={Tick} alt="" />
            <span>Over 140+ expert Coaches</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>Train smarter and faster than before</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>1 Free program for new member</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>Reliable Partners</span>
          </div>
        </div>
        <span style={{ color: "var(--gray)", fontWeight: "bold" }}>
            Our Partners
        </span>
        <div className="partners">
            <img src={Nike} alt="" />
            <img src={Adidas} alt="" />
            <img src={Nb} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
