import React from "react";
import "./join.css";

const Join = () => {
  return (
    <div className="Join" id="join-us">
      <div className="left__j">
      <hr />
        <div>
          <span className="stroke__text">Ready to </span>
          <span>Level up</span>
        </div>
        <div>
          <span>Your body</span>
          <span className="stroke__text"> with us</span>
        </div>
      </div>
      <div className="right__j">
        <form className="email__container">
          <input type="email" name="user_email" placeholder="Enter Your Email " />
          <button className="btn btn__j">join Now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
